export enum OPERATION_NAME_ENUM {
    match = 'match',
    haveIt = 'haveIt',
    wantIt = 'wantIt',
    giveYouAHand = 'giveYouAHand',
    giveMeAHand = 'giveMeAHand',
}

export enum OPERATION_NAME_ENUM_ITR {
    haveIt = "1",
    wantIt = "2",
    giveYouAHand = "3",
    giveMeAHand = "4",
}
